.legal-content {
  img.legal-Img {
    width: 100%;
    margin: 3em auto 0;
  }
}

@media screen and (max-width: 960px) {

}

@media screen and (max-width: 960px) {
 
}