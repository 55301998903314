.content-page {
  width:95%;
  max-width: 960px;
  margin: 3em auto;
  
  h2.sectionTitle {
    font-size: 2rem;
    text-align: left;
    padding-bottom: 0.5em;
  }

  .cart-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, auto));
    grid-template-rows: auto;
    grid-gap: 10px;
    justify-content: center;
    max-width: 960px;
    margin: 0 auto 0;
  }
  .cart-card { 
    max-width: 232.5px;
    position: relative;
    .card-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      justify-content: flex-end;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 1px solid rgba(0,0,0,.125);
      border-radius: .25rem;
      padding: .15em;
      height: 100%;

      img { 
        width: 100px;
        margin: 1em auto 0.5em;
      }

      .card-title {
        font-weight: 600;
        padding: 0 0.5em;
        margin: 1em 0 1.5em;
        text-transform: uppercase;
      }
    
      .cart-btn {
        width: 80%;
        margin: 0 auto 1rem;
        border-radius: 7px;
        background: #88bc42;
        a {
          color: #fff;
        }
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .content-page {
    h2.sectionTitle {
      font-size: 1.75rem;
    }
  }
}